// Packages & routers
import './App.css';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

// Pages
import { Auth } from "./pages/auth";
import { CreateCompetition } from './pages/createCompetition';
import { Home } from "./pages/home";
import { Competitions } from "./pages/competitions";
import { Onboarding } from "./pages/onboarding"
import { Selection } from "./pages/selection";
import { Standings } from "./pages/standings";
import { Testing } from "./pages/testing";

// Components
import { Navbar } from './components/navbar';
import { GetUserData } from "./components/getUserData";

export default function App() {

  // backend uri
  const backend = process.env.REACT_APP_SERVER_URI;

  // re-render 
  const [key, setKey] = useState(0);

  // function to change the 'key' re-render trigger state
  function incrementKey() {
    setKey((prevKey) => prevKey + 1)
  };

  // auth state
  const [cookies, removeCookie] = useCookies(['userToken', 'page', 'refreshed']);

  const userToken = cookies.userToken && cookies.userToken !== 'undefined' ? cookies.userToken : "none";

  // data
  const [gameData, setGameData] = useState({});
  const [selectionsAgg, setSelectionsAgg] = useState([]);

  // derrived state
  const [selectedCompetition, setSelectedCompetition] = useState({});
  const [selectedAccount, setSelectedAccount] = useState({});
  const [authenticatedUser, setAuthenticatedUser] = useState({});

  // trigger state
  const [competitionChanged, setCompetitionChanged] = useState(false);
  const [accountChanged, setAccountChanged] = useState(false);
  const [teamChanged, toggleTeamChanged] = useState(false);

  // get all user/account/compettion data from the backend 
  useEffect(() => {
    if (userToken !== 'none') {
      async function getData() {
        try {
          const result = await GetUserData('userdata', userToken);

          if (result.status === 200) {
            console.log(result.data.message);
            setGameData(result.data.payload);
            incrementKey();
          };
          if (result.status === 403) {
            console.log('token invalid or expired');
          };
        }
        catch (err) {
          console.error(err);
        }
      };
      getData();
    }
  }, [userToken, teamChanged, accountChanged, competitionChanged]);

  // if user is authenticated (gameData received), set the selectedAccount and selectedCompetition
  useEffect(() => {
    if (gameData) {
      setSelectedCompetition(gameData.selectedCompetition);
      setSelectedAccount(gameData.selectedAccount);
      setAuthenticatedUser(gameData.user)
    }
  }, [gameData]);

  // get selections
  useEffect(() => {
    if (userToken !== 'none') {
      async function getSelections() {
        try {
          const result = await GetUserData('selections', userToken);
          if (result.status === 200) {
            const accounts = await result.data.payload.accounts;
            const flattenedArray = accounts.flatMap((obj) => {
              return obj.selections.map((selection) => ({
                ...selection,
                account_name: obj.name, // Add the account name to each selection
              }));
            });


            setSelectionsAgg(flattenedArray);
          } else {
            alert(result.message);
            console.log(result);
          }
        } catch (err) {
          console.error(err);
        }
      }
      getSelections();
    }
  }, [userToken, teamChanged]);

  // remove userToken cookie on user logout
  function handleLogout() {
    // Remove the userToken cookie
    removeCookie('userToken');
    console.log('successfully logged out');
    incrementKey();
    window.location.reload();

  };

  // update the account selection for the authenticated user & re-render
  async function handleAccountSelection(accountName) {
    try {
      await axios.post(`${backend}/update/selectedaccount`, {
        accountName,
        userToken,
      });
      setAccountChanged(!accountChanged);
    }
    catch (err) {
      console.error(err);
    }
  };

  // update the competition selection for the selected user & re-render
  async function handleCompetitionSelection(competitionName) {
    try {
      // TODO this route doesn't exist!!
      await axios.post(`${backend}/update/selectedcompetition`, {
        userToken,
        compName: competitionName,
      });
      setCompetitionChanged(!competitionChanged);
    }
    catch (err) {
      console.error(err);
    }
  };

  // update the team selection for the selected account & re-render
  async function handleTeamSelection(newlySelectedTeamID) {
    const dateTimeNow = new Date();

    try {

      await axios.post(`${backend}/update/selections`, {
        userToken,
        round: selectedCompetition.gameParams.round,
        date: dateTimeNow,
        team_id: newlySelectedTeamID,
        result: 'pending',
        competitionName: selectedCompetition.name,
      });
      toggleTeamChanged(!teamChanged)
    }
    catch (err) {
      console.error(err);
    }
  };

  // jsx fragment (login/auth page) to be rendered if user is not authenticated
  const authElement = <><Auth
    incrementKey={incrementKey}
    userToken={userToken}
  /></>

  return (
    <div className="App" key={key}>
      <Router>
        <Navbar
          handleLogout={handleLogout}
          authenticatedUser={authenticatedUser}
          incrementKey={incrementKey}
        />

        <Routes>
          <Route
            path="/"
            element={<Home
            />}
          />
          <Route
            path="/auth"
            element={authElement}
          />
          <Route
            path="/onboarding"
            element={authenticatedUser ? <Onboarding
            /> : authElement}
          />
          <Route
            path="/create-competition"
            element={authenticatedUser ? <CreateCompetition
              authenticatedUser={authenticatedUser}
            /> : authElement}
          />
          <Route
            path="/competitions"
            element={authenticatedUser ? <Competitions
              authenticatedUser={authenticatedUser}
              selectedCompetition={selectedCompetition}
              handleCompetitionSelection={handleCompetitionSelection}
              incrementKey={incrementKey}
            /> : authElement}
          />
          <Route
            path="/selection"
            element={authenticatedUser ? <Selection
              authenticatedUser={authenticatedUser}
              selectedCompetition={selectedCompetition}
              selectedAccount={selectedAccount}
              handleAccountSelection={handleAccountSelection}
              handleTeamSelection={handleTeamSelection}
              selectionsAgg={selectionsAgg}
            /> : authElement}
          />
          <Route
            path="/standings"
            element={authenticatedUser ? <Standings
              selectedCompetition={selectedCompetition}
              selectedAccount={selectedAccount}
              selectionsAgg={selectionsAgg}
            /> : authElement}
          />
          <Route
            path="/testing"
            element={authenticatedUser ? <Testing
              incrementKey={incrementKey}
            /> : authElement}
          />
        </Routes>
      </Router>
    </div>
  );
}


