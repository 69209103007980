import { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;


export function Auth({ incrementKey, userToken }) {

    const [loginOrRegister, setLoginOrRegister] = useState("login");

    const [passedEmail, setPassedEmail] = useState("");

    function handleShowLoginBtn() {
        setLoginOrRegister("login")
    }

    function handleShowRegisterBtn() {
        setLoginOrRegister("register")
    }

    function handleShowForgotPassBtn() {
        setLoginOrRegister("forgotPass")
    }

    function handleShowChangePasswordBtn() {
        setLoginOrRegister("changePass")
    }

    function handlePassEmail(receivedEmail) {
        setPassedEmail(receivedEmail)
    }

    return <main className="authPage">
        <div className='banner-LMS'>
            <img src="/logo.png" alt="Logo" className="banner-img-LMS" />
        </div>
        {loginOrRegister === "login" && (
            <Login
                loginOrRegister={loginOrRegister}
                handleShowRegisterBtn={handleShowRegisterBtn}
                passedEmail={passedEmail}
                handleShowForgotPassBtn={handleShowForgotPassBtn}
                handlePassEmail={handlePassEmail}
                userToken={userToken}
                incrementKey={incrementKey}
            />)}

        {loginOrRegister === "register" &&
            (<Register
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
                handlePassEmail={handlePassEmail}
                passedEmail={passedEmail}
            />)}

        {loginOrRegister === "forgotPass" &&
            (<ForgotPassword
                loginOrRegister={loginOrRegister}
                handleShowLoginBtn={handleShowLoginBtn}
                handleShowChangePasswordBtn={handleShowChangePasswordBtn}
                handlePassEmail={handlePassEmail}
                passedEmail={passedEmail}
            />)}

        {loginOrRegister === "changePass" &&
            (<ChangePassword
                loginOrRegister={loginOrRegister}
                passedEmail={passedEmail}
                handleShowLoginBtn={handleShowLoginBtn}
                handlePassEmail={handlePassEmail}
            />)}

    </main>
};

function Login({ loginOrRegister, handleShowForgotPassBtn, handleShowRegisterBtn, passedEmail, handlePassEmail, userToken, incrementKey }) {

    const navigate = useNavigate();

    // redirect from here if already authenticated. Shouldn't happen anyway as the navbar prevents access to the auth route if already authenticated, however this hook handles the condition where the /auth URL may be input manually.
    useEffect(() => {
        if (userToken !== 'none') {
            navigate('/competitions');
            return
        }
    }, [userToken, navigate])

    const [email, setEmail] = useState(passedEmail || "");
    const [password, setPassword] = useState("");

    const [, setCookies] = useCookies("userToken", "refreshed", "page");
    const cookieLife = 10;
    useEffect(() => {
        setCookies("page", "auth", {
            maxAge: cookieLife,
            path: '/'
        });
    }, [setCookies]);

    // Attach the event listener when the component mounts
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // Set a cookie when the user refreshes the page
            setCookies("refreshed", "true", {
                maxAge: cookieLife,
                path: '/'
            });
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [setCookies]);


    const onSubmit = async (event) => {
        event.preventDefault();

        if (password === "" && email === "") {
            return alert("Please enter your account email address and password");
        };
        if (password === "") {
            handlePassEmail(email);
            return swal("Please enter your account password");
        };
        if (email === "") {
            return swal("Please enter your account email address");
        };

        try {

            const emailClean = email.toLowerCase().trim();

            const response = await axios.post(`${backend}/authenticate/signin`, {
                email: emailClean,
                password,
                userToken,
            });
            const responseAwait = await response.data;

            if (responseAwait && response.status === 200) {
                setCookies("userToken", response.data.newToken, {
                    maxAge: (60 * 60 * 24 * 7),
                    path: '/',
                });
                incrementKey();
                return
            }

            if (response.status === 401) {
                handlePassEmail(email);
                return swal("Password incorrect");
            }

            else return swal("An error occurred while processing your request");
        } catch (err) {
            console.error(err);

            if (err.response && err.response.status >= 400 && err.response.status < 500) {
                return swal(err.response.data.message || "An error occurred while processing your request");
            }

            // If the error doesn't match any specific case, provide a generic message
            return swal("An unexpected error occurred.");
        }
    }

    return <div className='login-container'>
        <Form
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            label="Login"
            onSubmit={onSubmit}
            loginOrRegister={loginOrRegister}
            handleShowForgotPassBtn={handleShowForgotPassBtn}
            handleShowRegisterBtn={handleShowRegisterBtn}
        />
        <p></p>
        <button type='button' onClick={handleShowRegisterBtn}>Register</button>
        <p></p>
        <button type='button' onClick={handleShowForgotPassBtn}>Forgot Password?</button>
    </div>
};

function Register({ loginOrRegister, handleShowLoginBtn, handlePassEmail, passedEmail }) {

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState(passedEmail || "");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [phone, setPhone] = useState("");

    const navigate = useNavigate();


    const onSubmit = async (event) => {
        event.preventDefault();

        if (username === "") {
            return swal("Please enter an account username")
        };
        if (email === "") {
            return swal("Please enter an account email")
        };
        if (password === "") {
            return swal("Please enter a password")
        };
        if (phone === "") {
            return swal("Please enter a contact number.")
        };
        if (password !== password2) {
            return swal("Passwords don't match! Please check and try again.")
        };

        try {
            const emailClean = email.toLowerCase().trim();

            const result = await axios.post(`${backend}/authenticate/signup`, {
                username,
                email: emailClean,
                password,
                phone
            });
            if (result.data.status === "SUCCESS") {
                swal("Registration submitted! Please check your inbox for a 'Verify Email' message and click the message link to complete the registration process.");
                handlePassEmail(email);
                window.location.reload();
                navigate('/auth')
                handleShowLoginBtn();
            } else {
                swal(`${result.data.message}`);
            }
        } catch (err) {
            console.error(err);
        };
    }
    return <div className='login-container'>
        <Form
            username={username}
            setUsername={setUsername}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            password2={password2}
            setPassword2={setPassword2}
            label="Register"
            onSubmit={onSubmit}
            loginOrRegister={loginOrRegister}
            handleShowLoginBtn={handleShowLoginBtn}
            phone={phone}
            setPhone={setPhone}
        />
        <p></p>
        <button type='button' onClick={handleShowLoginBtn}>Return to Login</button>
    </div>
};

function ForgotPassword({ handleShowLoginBtn, loginOrRegister, handleShowChangePasswordBtn, handlePassEmail, passedEmail }) {

    const [email, setEmail] = useState(passedEmail || "");

    const onSubmit = async (event) => {
        event.preventDefault();

        if (email === "") {
            return swal("Please enter an account email")
        };

        try {
            const emailClean = email.toLowerCase().trim();

            const result = await axios.post(`${backend}/authenticate/forgotpass`, {
                email: emailClean,
            });
            if (result.data.status === "SUCCESS") {
                swal("Please check your inbox for a 'ChangePassword'email and click the message link to change your password.");
                handlePassEmail(email);
                handleShowChangePasswordBtn();
            } else
                swal(`${result.data.message}`);

        } catch (err) {
            console.error(err);
        };
    }
    return <div className='login-container'>
        <Form
            email={email}
            setEmail={setEmail}
            label="Forgot Password"
            onSubmit={onSubmit}
            loginOrRegister={loginOrRegister}
            handleShowLoginBtn={handleShowLoginBtn}
        />

        <p></p>
        <button type='button' onClick={handleShowLoginBtn}>Return to Login</button>
    </div>
};

function ChangePassword({ loginOrRegister, handleShowLoginBtn, passedEmail }) {

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const navigate = useNavigate();

    const onSubmit = async (event) => {
        event.preventDefault();

        if (password === "") {
            return swal("Please enter a password")
        };
        if (password !== password2) {
            return swal("Passwords don't match! Please check and try again.")
        };
        try {
            const emailClean = passedEmail.toLowerCase().trim();

            const result = await axios.post(`${backend}/authenticate/changepass`, {
                email: emailClean,
                password
            });
            if (result.data.status === "SUCCESS") {
                swal("Password changed. Please log in with your new password.");
                window.location.reload();
                navigate('/auth')
                handleShowLoginBtn();
            }
            else
                swal(`${result.data.message}`);

        } catch (err) {
            console.error(err);
        };
    }
    return <div className='login-container'>
        <Form
            email={passedEmail}
            password={password}
            setPassword={setPassword}
            password2={password2}
            setPassword2={setPassword2}
            label="Change Password"
            onSubmit={onSubmit}
            loginOrRegister={loginOrRegister}
            handleShowLoginBtn={handleShowLoginBtn}
        />
        <p></p>
        <button type='button' onClick={handleShowLoginBtn}>Return to Login</button>

    </div>
};

const Form = ({ username, setUsername, password, setPassword, password2, setPassword2, label, onSubmit, email, setEmail, loginOrRegister, setPhone, phone }) => {
    return (
        <div >
            <form onSubmit={onSubmit} className="form" >
                <div className="form-group">
                    <h2> {label} </h2>
                </div>

                {loginOrRegister === "register" &&
                    (<div className="form-group">
                        <label htmlFor="username"> Full name: </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)} />
                    </div>)}

                {(loginOrRegister === "register" || loginOrRegister === "login") &&
                    (<div className="form-group">
                        <label htmlFor="email"> Email: </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </div>)}

                {loginOrRegister === "forgotPass" &&
                    (<div className="form-group">
                        <label htmlFor="email"> Email: </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </div>)}


                {loginOrRegister === "register" &&
                    (<div className="form-group">
                        <label htmlFor="phone"> Contact Number: </label>
                        <input
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)} />
                    </div>)}

                {(loginOrRegister === "register" || loginOrRegister === "login") && (<div className="form-group">
                    <label htmlFor="password"> Password: </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)} />
                </div>)}

                {loginOrRegister === "changePass" && (<div className="form-group">
                    <label htmlFor="password"> Password: </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)} />
                </div>)}

                {((loginOrRegister === "changePass") || (loginOrRegister === "register")) &&
                    (<div className="form-group">
                        <label htmlFor="password"> Repeat Password: </label>
                        <input
                            type="password"
                            id="password2"
                            value={password2}
                            onChange={(event) => setPassword2(event.target.value)} />
                    </div>)}



                <div className="form-group"><button type="submit"> {loginOrRegister === "register" || loginOrRegister === "login" || loginOrRegister === "changePass" ? label : "Send password reset"}</button></div>

            </form>
        </div>
    )
};




