import { loadStripe } from '@stripe/stripe-js'; // Corrected import statement

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

// Stripe public key
const stripePromise = loadStripe(process.env.REACT_APP_NODE_ENV !== 'dev' ? 'pk_live_51NzHtBImvAtotwI3pj47YSkyemjlvF4B5VpP0wpQAm6Xz9KvqM4AUjP8dx6GMVwmZZtTXITI2i81Qt6j4gHYtMSN00si2qBAD9' : 'pk_test_51NzHtBImvAtotwI3qcK6mfUq8pNx0xs7OCfOCcBNJHIWFRmejRvGVGCPcJd41o3LU84CKSarcECBlCftGzFJGbQ300FEL1RdfI')

const InitiatePayment = ({ lineItems, authenticatedUser }) => {
    const handleCheckout = async () => {
        const stripe = await stripePromise;
        // Call backend to create a Checkout Session
        const response = await fetch(`${backend}/stripe/createcheckout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                lineItems,
                email: authenticatedUser.email,
                userName: authenticatedUser.username
            })
        });

        const session = await response.json();

        // Redirect to the Stripe Checkout page
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return (
        <div>
            <button type="button" onClick={handleCheckout}>Checkout</button>
        </div>
    );
};

export default InitiatePayment;
