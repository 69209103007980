export function TableNew({ selectedAccount, selectedCompetition, round, view, selectionsAgg }) {
    const anyFixtureCommenced = selectedCompetition?.fixtures?.some(obj => obj.fixture.status.elapsed);

    const currentRound = (view === 'individual' || (view !== 'individual' && anyFixtureCommenced))
        ? Number(round)
        : Number(round) - 1;

    const gameweekStartRaw = selectedCompetition && (selectedCompetition.compParams?.startRound);

    const gameweekStart = parseInt(gameweekStartRaw.split(" - ")[1]);

    const numRounds = currentRound - (gameweekStart - 1);

    const columnData = Array.from({ length: numRounds }, (_, index) => String(gameweekStart + index));

    const selections = selectedAccount && selectionsAgg && view === 'individual'
        ? selectionsAgg.filter(obj => obj.account_name === selectedAccount.name)
        : selectedAccount && selectionsAgg && view === 'all'
            ? selectionsAgg
            : [];

    const accountResults = selections.reduce((acc, obj) => {
        const accountName = obj.account_name;
        const existingGroup = acc.find(group => group[0].account_name === accountName);


        if (existingGroup) {
            existingGroup.push(obj);
        } else {
            acc.push([obj]);
        }

        return acc;
    }, []);


    function setCellProps(cellObj) {

        if (!cellObj) {
            return ['standings-cell-empty', <span key="empty"></span>];
        }

        const { result, team_id, team_logo } = cellObj;

        const cellProps =
            result === 'lost' && team_id === 0
                ? ['standings-cell-missed', <img key="missed" className="standings-img" src="/homer.png" alt="non-selection" />]
                : result === 'lost'
                    ? ['standings-cell-lost', <img key="lost" className="standings-img" src={team_logo} alt="lost" />]
                    : result === 'won'
                        ? ['standings-cell-won', <img key="won" className="standings-img" src={team_logo} alt="won" />]
                        : result === 'void'
                            ? ['standings-cell-void', <img key="void" className="standings-img" src={team_logo} alt="void" />]
                            : result === 'pending'
                                ? ['standings-cell-pending', <img key="pending" className="standings-img" src={team_logo} alt="pending" />]
                                : null;

        return cellProps;
    }

    return (
        <table className="standings-component">
            <thead>
                <tr>
                    <th className="standings-header"></th>
                    {columnData.map(item => (
                        <th key={item} className="standings-header-rounds">{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {accountResults.map(account => (
                    <tr key={account[0].account_name}>
                        <td className="standings-firstcol">{account[0].account_name}</td>
                        {columnData.map((i) => {
                            const resultObj = account.find(selection => selection.round === `Regular Season - ${i}`);
                            const [className, jsxElement] = setCellProps(resultObj);

                            return (
                                <td key={i} className={className}>{jsxElement}</td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
