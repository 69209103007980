import { TableNew } from '../components/tableNew';
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";


export function Standings({ selectedCompetition, selectedAccount, selectionsAgg }) {

    const [selectionsAggSorted, setSelectionsAggSorted] = useState([]);

    const roundFull = selectedCompetition && selectedCompetition.gameParams?.round;
    const round = roundFull && roundFull.split("- ")[1];
    const view = "all"
    const navigate = useNavigate();

    function onCompetitions() {
        navigate('/competitions');
    };

    function sortArray(arr) {
        const sortedArray = [...arr].sort((a, b) => {
            // First, compare by the number of "win" results (descending order)
            const winsComparison = b.result === "won" ? 1 : a.result === "won" ? -1 : 0;

            // If the number of "win" results is different, return the comparison result
            if (winsComparison !== 0) {
                return winsComparison;
            }

            // If the number of "win" results is the same, compare by user name (ascending order)
            const userA = a.user && a.user.toLowerCase();
            const userB = b.user && b.user.toLowerCase();

            if (userA < userB) {
                return -1;
            }
            if (userA > userB) {
                return 1;
            }
            return 0;
        });

        return sortedArray;
    };

    // sort array
    useEffect(() => {
        if (selectionsAgg && selectionsAgg.length > 0) {
            const sortedArr = sortArray(selectionsAgg);
            setSelectionsAggSorted(sortedArr);
        }
    }, [selectionsAgg]);


    const [, setCookies] = useCookies("userToken", "refreshed", "page");

    useEffect(() => {
        const cookieLife = 10;
        setCookies("page", "standings", {
            maxAge: cookieLife,
            path: '/'
        });
    }, [setCookies]);

    // Attach the event listener when the component mounts
    useEffect(() => {
        const cookieLife = 10;
        const handleBeforeUnload = (e) => {
            // Set a cookie when the user refreshes the page
            setCookies("refreshed", "true", {
                maxAge: cookieLife,
                path: '/'
            });
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [setCookies]);

    function handleClickShowAll() {
        const sortedArr = sortArray(selectionsAgg)
        setSelectionsAggSorted(sortedArr)
    };

    function handleClickHideBust() {
        // Filter the array to remove objects with the same account_id and "lost" result
        const filteredData = selectionsAggSorted.filter((item, index, self) => {
            // Check if the current item has an account_id and result combination that appears only once
            return (
                self.findIndex(
                    (obj) =>
                        obj.account_name === item.account_name &&
                        obj.result === "lost" &&
                        obj !== item
                ) === -1
            );
        });
        setSelectionsAggSorted(sortArray(filteredData));
    };

    function handleClickSortAz() {
        setSelectionsAggSorted([...selectionsAggSorted].sort((a, b) => {
            const userA = a.account_name.toLowerCase(); // Convert to lowercase for case-insensitive sorting
            const userB = b.account_name.toLowerCase();

            if (userA < userB) {
                return -1; // User A comes before User B
            }
            if (userA > userB) {
                return 1; // User A comes after User B
            }
            return 0; // Users are the same
        }));
    };

    function handleClickSortWin() {
        setSelectionsAggSorted(sortArray(selectionsAggSorted))
    };

    const pageColour = selectedCompetition ? selectedCompetition.compParams?.home_background : '#FFFFFF';


    return (
        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: `#${pageColour}`,
        }}>
            <div style={{
                display: 'flex',
                paddingTop: '3%',
                paddingBottom: '3%',
                margin: '0',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: `#${pageColour}`,
                width: '100 %'
            }}>
                {selectedCompetition.name === 'TestComp' ? <img src="/dummies.jpg" alt="dummies" className="banner-img" /> : <img src="/pirates_banner.jpg" alt="Logo" className="banner-img" />}
            </div>
            <div className="standings-container">
                <button type="button" onClick={onCompetitions}>Competition Home</button>
                <h2>Competition Standings</h2>
                <ul className='filter-buttons-list'>
                    <li><button type='filter-button' onClick={handleClickShowAll}>show all</button></li>
                    <li><button type='filter-button' onClick={handleClickHideBust}>hide bust</button></li>
                    <li><button type='filter-button' onClick={handleClickSortAz}>sort by a-z</button></li>
                    <li><button type='filter-button' onClick={handleClickSortWin}>sort by wins</button></li>
                </ul>
                <TableNew
                    selectedAccount={selectedAccount}
                    selectedCompetition={selectedCompetition}
                    selectionsAgg={selectionsAggSorted}
                    round={round}
                    view={view}
                />
            </div>
        </main >
    )
}

export default Standings;
