import { useState, useEffect } from "react";

export function AccountContainer({ authenticatedUser, selectedAccount, handleAccountSelection, losingTeams }) {
    const [selectedAccountName, setSelectedAccountName] = useState('');
    const livesRemaining = losingTeams.length === 1 ? `2 lives remaining! ⚽⚽` : losingTeams.length === 2 ? `1 life remaining! 💀⚽` : `game over! 💀💀`;

    useEffect(() => {
        if (selectedAccount) {
            setSelectedAccountName(selectedAccount.name)
        }
    }, [selectedAccount]);

    function handleChange(e) {
        const accountName = e.target.value;
        handleAccountSelection(accountName);
    };

    if (selectedAccount && authenticatedUser.accounts) {
        return (
            <div className="account-container">
                <div className="account_select">
                    <h2>👋 {authenticatedUser.username}</h2>
                    <label className="account_select_label">Selected LMS account:
                        <br></br>
                        <select value={selectedAccountName} onChange={(e) => handleChange(e)
                        } className="account_dropdown">{authenticatedUser.accounts.map((item) => (<option key={item.name} value={item.name}>{item.name}</option>
                        ))}
                        </select>
                    </label>
                </div>
                <p>{livesRemaining}</p>
            </div>
        )
    }
};