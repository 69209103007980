import axios from 'axios';
import { useState, useEffect } from 'react';
import { JSONTree } from 'react-json-tree';
import { Monokai } from '../helpers/themes';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;

export function Testing({ incrementKey }) {

    const [gameweekDB, setGameweekDB] = useState("");
    const [positionDB, setPositionDB] = useState("");
    const [gameweekUI, setGameweekUI] = useState("selection");
    const [positionUI, setPositionUI] = useState("selection");
    const [fixturesObj, setFixturesObj] = useState({});
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const [, setCookies] = useCookies("access_token", "refreshed", "page");
    const cookieLife = 10;
    useEffect(() => {
        setCookies("page", "testing", {
            maxAge: cookieLife,
            path: '/'
        });
    }, [setCookies]);

    // get the current test parameters from the testing collection
    useEffect(() => {
        async function runGetDbData() {
            try {
                const colTestParams = "testing";
                const testParamsFilter = 'getTestComp';
                const testParams = await getTestData(colTestParams, testParamsFilter);
                setGameweekDB(testParams.gameweek);
                setPositionDB(testParams.position);
            }
            catch (err) {
                console.error(err);
            }
        };
        runGetDbData();
    }, []);

    // get the current test competition's fixtures
    useEffect(() => {
        async function runGetDbData() {
            try {
                const colFixtures = 'competitions';
                const fixturesFilter = 'getTestComp';
                const competition = await getTestData(colFixtures, fixturesFilter);
                setFixturesObj(competition.fixtures);
            }
            catch (err) {
                console.error(err);
            }
        };
        runGetDbData();
    }, []);

    // Attach the event listener when the component mounts
    useEffect(() => {
        const cookieLife = 10;
        const handleBeforeUnload = (e) => {
            // Set a cookie when the user refreshes the page
            setCookies("refreshed", "true", {
                maxAge: cookieLife,
                path: '/'
            });
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [setCookies]);

    const onSubmitActions = async (event) => {
        event.preventDefault();
        setSubmitButtonDisabled(true);

        if ((gameweekDB === gameweekUI) && (positionDB === positionUI)) {
            swal("Those selected test parameters are already set in the database!")
            setSubmitButtonDisabled(false);
            return
        } else {
            try {
                const collection = "testing";
                const filter = {};
                const results = await getTestData(collection, filter);
                setGameweekDB(results.gameweek);
                setPositionDB(results.position);
                if (gameweekUI !== 'selection' && positionUI !== 'selection') {
                    try {
                        const response = await axios.post(`${backend}/testing/databaseActions`, {
                            gameweekUI,
                            positionUI,
                        });

                        if (response.status === 200) {
                            setSubmitButtonDisabled(false);

                            swal(`Fixtures object updated. All selections for gameweeks beyond${gameweekUI === 'Regular Season - 1' && positionUI === 'fixtures_ns' ? ' and including' : ''} ${gameweekUI} have been deleted.`);

                            try {
                                await postTestCase();
                                const colTestParams = "testing";
                                const colFixtures = 'fixtures';
                                const filter = {};
                                const testParams = await getTestData(colTestParams, filter);
                                setGameweekDB(testParams.gameweek);
                                setPositionDB(testParams.position);
                                const fixtures = await getTestData(colFixtures, filter);
                                setFixturesObj(fixtures);
                                // window.location.reload();
                                incrementKey()
                            }
                            catch (err) {
                                console.error(err);
                            }
                        } else {
                            // Handle other status codes (e.g., 400, 500) with appropriate messages.
                            console.error(`Server error: ${response.status}`);
                            swal("An error occurred while processing your request.");
                        }
                    } catch (err) {
                        console.error(err);
                        swal("An error occurred while processing your request.");
                    }

                } else {
                    swal(`please make selections for both Gameweek and Position`); setSubmitButtonDisabled(false);
                }
            }
            catch (err) {
                console.error(err);
            }
        };
    };

    const onClickUpdateSelections = async () => {
        if (gameweekDB && positionDB) {
            try {
                const response = await axios.post(`${backend}/testing/databaseUpdates`);

                if (response.status === 200) {

                    swal(`Selections updated with results.`);

                    window.location.reload();

                } else {

                    console.error(`Server error: ${response.status}`);
                    swal("An error occurred while processing your request.");
                }
            } catch (err) {
                console.error(err);
                swal("An error occurred while processing your request.");
            }

        } else { swal(`please make selections for both Gameweek and Position`) }
    };

    // function that fetches DB data
    const getTestData = async (collection, filter) => {

        try {
            const response = await axios.post(`${backend}/testing/getDbData`, {
                collection,
                filter
            });

            if (response.status === 200) {
                const data = response.data;
                return await data

            } else {
                console.error(`Server error: ${response.status}`);
                swal("An error occurred while processing your request.");
            }
        } catch (err) {
            console.error(err);
            swal("An error occurred while processing your request.");
        }
    };

    const postTestCase = async () => {
        try {
            const response = await axios.post(`${backend}/testing/postTestCase`, {
                gameweekUI,
                positionUI,
               });

            if (response.status === 200) {
                const data = response.data;
                return await data

            } else {
                console.error(`Server error: ${response.status}`);
                swal("An error occurred while processing your request.");
            }
        } catch (err) {
            console.error(err);
            swal("An error occurred while processing your request.");
        }
    };

    return (
        <main className="testingPage">
            <Form
                gameweekUI={gameweekUI}
                setGameweekUI={setGameweekUI}
                positionUI={positionUI}
                setPositionUI={setPositionUI}
                label="Select test parameters"
                onSubmit={onSubmitActions}
                submitButtonDisabled={submitButtonDisabled}
            />
            <Results
                fixturesObj={fixturesObj}
                onClick={onClickUpdateSelections}
                gameweekDB={gameweekDB}
                positionDB={positionDB}
            />
        </main>
    )
};

function Form({ gameweekUI, setGameweekUI, positionUI, setPositionUI, label, onSubmit, submitButtonDisabled }) {
    return (
        <div className="test-form-container">
            <form className={"form"} onSubmit={onSubmit} >
                <h2> {label} </h2>
                <div className="form-group">
                    <label htmlFor="models-label"> Gameweek: </label>
                    <select
                        className="account_dropdown"
                        value={gameweekUI}
                        onChange={(event) => { setGameweekUI(event.target.value) }}>
                        <option key="default" value="selection">Please select</option>
                        <option key="gameweek 1" value="Regular Season - 1">Gw 1</option>
                        <option key="gameweek 2" value="Regular Season - 2">Gw 2</option>
                        <option key="gameweek 3" value="Regular Season - 3">Gw 3</option>
                        <option key="gameweek 4" value="Regular Season - 4">Gw 4</option>
                        <option key="gameweek 5" value="Regular Season - 5">Gw 5</option>
                    </select>
                    <p></p>
                    <label htmlFor="methods-label"> Position: </label>
                    <select
                        className="account_dropdown"
                        value={positionUI}
                        onChange={(event) => { setPositionUI(event.target.value) }}>
                        <option key="default" value="selection">Please select</option>
                        <option key="position 1" value="fixtures_ns">none started</option>
                        <option key="position 2" value="fixtures_fs">first started</option>
                        <option key="position 3" value="fixtures_sf">second finished</option>
                        <option key="position 4" value="fixtures_af">all finished</option>
                    </select>
                    <p></p>
                </div>

                <button type="submit" disabled={submitButtonDisabled}>Submit</button>
            </form>
        </div>
    )
};


function Results({ fixturesObj, onClick, gameweekDB, positionDB }) {
    const result = fixturesObj && (fixturesObj || []);
    return (
        <div className='test-results-container'>
            <h2>DB Gameweek = {!gameweekDB ? "please wait..." : gameweekDB === 'selection' ? '[Please select]' : gameweekDB}</h2>
            <h2>DB Position = {!positionDB ? "please wait..." : positionDB === 'selection' ? '[Please select]' : positionDB}</h2>
            <h2>DB Fixtures Object:</h2>
            <h2 className='test-results-text'>
                <JSONTree data={result} theme={Monokai} />
            </h2>
            <button
                onClick={onClick}
                type="button">
                Update/Result Selections
            </button>
        </div>
    )
};




