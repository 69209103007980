import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export function Home() {

    const [cookies,] = useCookies("refreshed");
    const redirect = cookies.refreshed && cookies.page;

    const navigate = useNavigate();


    if (redirect) { navigate(`/${cookies.page}`) };

    return <main className="homePage">
        <div className='banner-LMS'>
            <img src="/logo.png" alt="Logo" className="banner-img-LMS" />
        </div>
        <div className='home-container'>

            <h1>Welcome to Last Man Standing!</h1>
            <h2>
                LastManStanding.fun is a platform that hosts prize competitions designed to raise money for clubs, organisations and charities alike.
            </h2>
            <h3>
                How the site works:
            </h3>
            <h4>
                {`Competitions can be created by anyone to support their cause.`}
                <br></br>
                <br></br>
                {`Competition creators will set the participation entrance fee, the number of lives competitors will each receive, and most importantly the prize pots up for grabs!`}


            </h4>
            <h3>
                The competition rules:
            </h3>
            <h4>
                {`1) The competition follows the Premier League season and each Premier League game week, players must choose one team who they think will win.`}
                <br></br>
                <br></br>
                {`2) If the player's selection wins, the player continues to the next round with all their lives intact. If the player's selection loses or draws, the player will lose one of their lives.`}
                <br></br>
                <br></br>
                {`3) Once a player has lost all their lives, they are eliminated from the competition.`}
                <br></br>
                <br></br>
                {`4) The competition continues until only one player remains, who will then be crowned the LAST MAN STANDING!`}
            </h4>
            <h3>
                Getting started:
            </h3>
            <h4>
                {`1) Head to 'Login/Register' in the top navigation bar.`}
                <br></br>
                <br></br>
                {`2) Click to 'Register' and enter your details to create a Last Man Sanding account.`}
                <br></br>
                <br></br>
                {`3) Verify your email address by following the verification link emailed to you.`}
                <br></br>
                <br></br>
                {`4)) Login with your account details and head to 'Competitions' in the top navigation bar.`}
            </h4>
            <h3>
                Joining a competition:
            </h3>
            <h4>
                {`1) Under 'Join competitions' enter the Joining Pin given to you by your Competition Administrator.`}
                <br></br>
                <br></br>
                {`2) Select the desired numbers of entries you wish to purchase for the competition.`}
                <br></br>
                <br></br>
                {`3) Complete your purchase via the secure payment link and return to Last Man Standing.`}
                <br></br>
                <br></br>
                {`4) Head to 'Competitions' again and click on 'Name Accounts' to input your display name for each of your purchased accounts.`}
                <br></br>
                <br></br>
                {`5) From the 'Competitions' page, click 'Selections' to start gaming!.`}
            </h4>
            <h3>
                Making your selection:
            </h3>
            <h4>
                {`🟨 Simply click on the team of your choice. Your selection will appear in yellow.`}
                <br></br>
                <br></br>
                {`⛔ Remember, each team can only be picked once!`}
                <br></br>
                <br></br>
                {`🟥🟩 Teams highlighted in red or green are your past losing/winning selections and cannot be picked again.`}
                <br></br>
                <br></br>
                {`🔄 You can continue to change your selection until the first game of the week kicks off.`}
                <br></br>
                <br></br>
                {`🤡 If you haven't made a selection by the time the first game kicks off, you will lose a life for that game week!`}
                <br></br>
            </h4>
            {/*<h3>
                Help us improve:
            </h3>
          <h4>
                {`🔍🐞  Spotted a bug?!`}
                <br></br>
                <br></br>
                {`😲💡 Have an idea?!`}
                <br></br>
                <br></br>
                Please let us know using <a target="_blank" rel="noreferrer" href="https://forms.office.com/e/J7QBWrQ35B">this form</a>.
            </h4>*/}
            <h3>
                Customer Support:
            </h3>
            <h4>
                Need to get in touch? Please contact us at <a href="mailto:support@last-man-standing.co.uk">support@last-man-standing.co.uk</a>
            </h4>
        </div >
    </main >

}