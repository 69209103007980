import axios from 'axios';

// backend uri
const backend = process.env.REACT_APP_SERVER_URI;


export async function startStripeConnectOnboarding(newLeagueObj) {

    try {
        const newAccount = await axios.post(`${backend}/stripe/createaccount`,
            newLeagueObj,
            {
                headers: {
                    authorization: `Bearer ${newLeagueObj.lmsUser.token}`,
                }
            }
        );
        console.log(newAccount.response);
    } catch (err) {
        console.error(err);
    };

};
