import { TableNew } from '../components/tableNew';
import { useNavigate } from "react-router-dom";

export function SelectionHeader({ selectedCompetition, selectedAccount, selectionsAgg }) {

  const roundFull = selectedCompetition && selectedCompetition.gameParams?.round;
  const round = roundFull && roundFull.split("- ")[1];
  const gameWeekText = `Game Week ${round}`
  const view = "individual"

  const navigate = useNavigate();

  function onStandings() {
    navigate('/standings');
  };

  return (
    <div className="header-container">
      {selectedCompetition?.gameParams?.round ? (Number(selectedCompetition.gameParams.round.split(" - ")[1]) < Number(selectedCompetition.compParams.startRound)) &&
        <><h3>COMPETITION STARTS: Game Week {selectedCompetition.compParams.startRound}</h3></> : null}
      <h3>{gameWeekText}</h3>
      <button type="button" onClick={onStandings}>Competition Standings</button>
      <h2>Your selections:</h2>
      <div className="selection-history">
        <TableNew selectedAccount={selectedAccount} selectionsAgg={selectionsAgg}
          selectedCompetition={selectedCompetition} round={round} view={view} />
      </div>
    </div >
  );
};
