
import { useNavigate } from 'react-router-dom';

export function Onboarding() {

    const navigate = useNavigate();
    function handleClick() {
        navigate('/create-competition')
    };

    return (
        <>
            <h2>To become a Competition Admin and create a Last Man Standing competition, you must first register with the Stripe payment platform to securely route competition entry fees to your bank account.</h2>
            <p></p>
            <button type="button" onClick={handleClick}>Let's Go!</button>
            <p></p>
        </>
    );
};