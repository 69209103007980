import swal from 'sweetalert';

export function FixtureList({ winningTeams, losingTeams, voidedTeams, handleTeamSelection, selectedCompetition, selectedAccount }) {

    // Add a check for selectedAccount
    if (!selectedAccount || !selectedCompetition || !selectedAccount.selections) {
        console.log('awaiting props');
        return <div>Loading...</div>; // or any other loading indicator
    };

    const currentRound = selectedCompetition.gameParams?.round;

    const selectionsArr = selectedAccount.selections;

    const noSelections = !selectionsArr || selectionsArr.length === 0;

    const roundTeamSelection = !noSelections && selectionsArr.find((obj) => obj.round === currentRound);

    function handleClick(item, homeAway) {

        if (Number(currentRound.split(" - ")[1]) < Number(selectedCompetition.compParams?.startRound)) { return }

        const gameOver = losingTeams.length > 2;

        const anyFixtureCommenced = selectedCompetition.fixtures.filter(obj => (obj.fixture.status.elapsed)).length > 0;

        const newlySelectedTeamID = homeAway === 'home' ? item.teams.home.id : item.teams.away.id;


        const differentSelection = noSelections ? true : !roundTeamSelection ? true :
            (newlySelectedTeamID !== roundTeamSelection.team_id) ? true : false;

        const allSelections = [...losingTeams, ...winningTeams];
        const availableSelection = !allSelections.includes(newlySelectedTeamID);

        if (differentSelection && availableSelection && !anyFixtureCommenced && !gameOver) {
            handleTeamSelection(newlySelectedTeamID);

            swal("Selection made!", "Please note you can continue to change your selection until the first fixture of this gameweek kicks off.");
        };
    };

    function returnClassName(item, homeAway) {
        const thisTeamID = homeAway === 'home' ? item.teams.home.id : item.teams.away.id;

        const elementSelected = roundTeamSelection && roundTeamSelection.team_id === thisTeamID && roundTeamSelection.result === 'pending';
        const elementWon = winningTeams.includes(thisTeamID);
        const elementLost = losingTeams.includes(thisTeamID);
        const elementVoided = voidedTeams.includes(thisTeamID);

        const resultingClass = elementSelected ? 'selected_team' : elementWon ? 'winning_selection' : elementLost ? 'losing_selection' : elementVoided ? 'voided_selection' : 'team_grids'

        return resultingClass
    };


    if (selectedCompetition && selectedCompetition.fixtures)
        return (
            <ul className="fixtures_list">
                {selectedCompetition.fixtures.map((item) => (
                    <li className="fixture_item" key={item.fixture.id}>
                        <div
                            key={item.teams.home.id}
                            id={item.teams.home.id}
                            onClick={() => handleClick(item, 'home')} // need to pass React a function callback, not call a function!
                            className={returnClassName(item, 'home')}>
                            <img src={item.teams.home.logo} alt={item.teams.home.name}></img>
                            <p>{item.teams.home.name}</p>
                        </div>
                        <div className="centre_grid">
                            <div className="date">
                                <p>{new Date(item.fixture.date.slice(0, 19)).toLocaleDateString('en-GB', { weekday: "short", day: "numeric", month: "short", year: "2-digit" })}</p>
                            </div>
                            <div className="kickoff">
                                <p>{new Date(item.fixture.date.slice(0, 19)).toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" })}</p>
                            </div>
                            <div className="score">
                                <p>{item.goals.home} : {item.goals.away}</p>
                            </div>
                            <div className="elapsed">
                                <p>{item.fixture.status.elapsed}</p>
                            </div>
                        </div>
                        <div
                            key={item.teams.away.id}
                            id={item.teams.away.id}
                            onClick={() => handleClick(item, 'away')} // need to pass React a function callback, not call a function!
                            className={returnClassName(item, 'away')}>
                            <img src={item.teams.away.logo} alt={item.teams.away.name}></img>
                            <p>{item.teams.away.name}</p>
                        </div>
                    </li>))}
            </ul>
        )

}
