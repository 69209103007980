import { useState, useEffect } from "react";
import { SelectionHeader } from '../components/selectionHeader';
import { AccountContainer } from "../components/accountContainer";
import { FixtureList } from '../components/fixtureList';
import { useCookies } from "react-cookie";

export function Selection({ authenticatedUser, selectedCompetition, selectedAccount, handleAccountSelection, handleTeamSelection, selectionsAgg }) {
  const [winningTeams, setWinningTeams] = useState([]);
  const [losingTeams, setLosingTeams] = useState([]);
  const [voidedTeams, setVoidedTeams] = useState([]);

  const [, setCookies] = useCookies("userToken", "refreshed", "page");

  const cookieLife = 10;
  useEffect(() => {
    setCookies("page", "selection", {
      maxAge: cookieLife,
      path: '/'
    });
  }, [setCookies]);

  // Attach the event listener when the component mounts
  useEffect(() => {
    const cookieLife = 10;
    const handleBeforeUnload = (e) => {
      // Set a cookie when the user refreshes the page
      setCookies("refreshed", "true", {
        maxAge: cookieLife,
        path: '/'
      });
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [setCookies]);

  useEffect(() => {

    let winners = [0];
    let losers = [0];
    let voids = [0];

    if (selectedAccount.selections) {
      selectedAccount.selections.forEach((selection) => selection.result === 'won' ? winners.push(selection.team_id) : null);

      selectedAccount.selections.forEach((selection) => selection.result === 'lost' ? losers.push(selection.team_id) : null);

      selectedAccount.selections.forEach((selection) => selection.result === 'void' ? voids.push(selection.team_id) : null);
    }

    setLosingTeams(losers);
    setWinningTeams(winners);
    setVoidedTeams(voids);

  }, [selectedAccount]);

  const pageColour = selectedCompetition ? selectedCompetition.compParams?.home_background : '#FFFFFF';

  return (
    <main style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      width: '100%',
      height: '100%',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
      backgroundColor: `#${pageColour}`,
    }}>
      <div style={{
        display: 'flex',
        paddingTop: '3%',
        paddingBottom: '3%',
        margin: '0',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `#${pageColour}`,
        width: '100 %'
      }}>
        {selectedCompetition.name === 'TestComp' ? <img src="/dummies.jpg" alt="dummies" className="banner-img" /> : <img src="/pirates_banner.jpg" alt="Logo" className="banner-img" />}
      </div>
      <AccountContainer
        className="account_container"
        authenticatedUser={authenticatedUser}
        selectedAccount={selectedAccount}
        handleAccountSelection={handleAccountSelection}
        losingTeams={losingTeams}
      />
      <SelectionHeader
        selectedCompetition={selectedCompetition}
        selectedAccount={selectedAccount}
        selectionsAgg={selectionsAgg}
      />
      <FixtureList
        winningTeams={winningTeams}
        losingTeams={losingTeams}
        voidedTeams={voidedTeams}
        handleTeamSelection={handleTeamSelection}
        selectedCompetition={selectedCompetition}
        selectedAccount={selectedAccount}
      />
    </main>
  )
};