import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function Navbar({ handleLogout, authenticatedUser, incrementKey }) {
    const navigate = useNavigate();

    const loggedIn = authenticatedUser && authenticatedUser !== 'undefined';

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    function toggleDrawer() {
        setIsDrawerOpen(!isDrawerOpen);
    }

    function onLoginLogout() {
        if (loggedIn) {
            navigate('/');
            handleLogout();
            toggleDrawer();
        } else {
            navigate('/auth');
            toggleDrawer();
        }
    }

    function onHome() {
        navigate('/');
        toggleDrawer();
    }

    function onCompetitions() {
        incrementKey();
        navigate('/competitions');
        toggleDrawer();
    }

    function onTesting() {
        navigate('/testing');
        toggleDrawer();
    }

    return (
        <div className="navbar">
            <nav>
                <div className="navbar-header">
                    <button className="navbar-toggle" onClick={toggleDrawer}>
                        <div className={`icon-bar ${isDrawerOpen ? 'open' : ''}`}></div>
                        <div className={`icon-bar ${isDrawerOpen ? 'open' : ''}`}></div>
                        <div className={`icon-bar ${isDrawerOpen ? 'open' : ''}`}></div>
                    </button>
                </div>
            </nav>
            <div className={`navbar-drawer ${isDrawerOpen ? 'open' : ''}`}>
                <ul>
                    <li>
                        <button type="nav-button" onClick={onHome}>
                            Home
                        </button>
                    </li>
                    {loggedIn && <>

                        <li>
                            <button type="nav-button" onClick={onCompetitions}>
                                Competitions
                            </button>
                        </li>
                    </>}

                    {loggedIn && (authenticatedUser?.email?.includes("hamech") || authenticatedUser?.email?.includes('adyon')) &&
                        <>
                            <li>
                                <button type="nav-button" onClick={onTesting}>
                                    Testing
                                </button>
                            </li>
                        </>}
                    <li>
                        <button type="nav-button" onClick={onLoginLogout}>
                            {loggedIn ? "Logout" : "Login/Register"}
                        </button>
                    </li>
                </ul>
            </div>
        </div >
    );
}
