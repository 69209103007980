import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import { startStripeConnectOnboarding } from "../components/connectAccount";
import { Slider } from '@mui/material'


export function CreateCompetition({ authenticatedUser }) {

    // Handle page refresh
    const [, setCookies] = useCookies("refreshed", "page");
    const cookieLife = 10;
    useEffect(() => {
        setCookies("page", "createleague", {
            maxAge: cookieLife,
            path: '/'
        });
    }, [setCookies]);

    const [stepCount, setStepCount] = useState(1);

    const navigate = useNavigate();

    function clickBack() {
        if (stepCount === 1) {
            setStepCount(stepCount - 1);
            navigate("/competitions");
        } else {
            setStepCount(stepCount - 1);
        }
    };

    const [formData, setFormData] = useState(() => {
        // Retrieve data from localStorage on component mount
        const storedData = localStorage.getItem('formData');
        return storedData ? JSON.parse(storedData) :
            {
                "lives": 1,
                "startWeek": 'gameweek1',
                "lmsUser": {
                    "email": authenticatedUser.email,
                    "username": authenticatedUser.username,
                    "userToken": authenticatedUser.token
                },
                "expectedEntrants": 100,
                "ticketPrice": 10000,
                "payoutPercentage": 10,
                "businessType": "",
                "companyName": "",
                "companyUrl": "",
                "individualFirstName": "",
                "individualLastName": "",
                "individualEmail": "",
                "organisationName:": ""
            };
    });

    // Update the stored data whenever formData changes
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);


    function Intro() {
        return (
            <div className="competition-container">
                <h1>Create a competition</h1>
                {stepCount === 1 &&
                    <h2>
                        Thank you for chosing to create a new competition! <p></p>
                        Let's start by entering the basic competition parameters.
                    </h2>
                }
                {stepCount === 2 &&
                    <h2>
                        Some blurb for step two
                    </h2>
                }
                <h3>
                    Step {stepCount} of x
                </h3>
            </div>
        )
    };

    function StepOne() {

        // Handle form input changes
        function submitStep(event) {
            const {
                competitionName,
                lives,
                ticketPrice,
                startWeek,
                expectedEntrants,
                payoutPercentage,
                lmsUser
            } = event.target;
            setFormData((prevData) => ({
                ...prevData,
                competitionName,
                lives,
                startWeek,
                lmsUser,
                expectedEntrants,
                ticketPrice,
                payoutPercentage
            }));
            setStepCount(stepCount + 1);
        };

        return (
            <div className="competition-checkout-container">
                <form onSubmit={submitStep}>
                    <h3>Competition name:</h3>
                    <input
                        value={formData.competitionName || ''}
                        onChange={(event) => { submitStep(event.target.value) }}>
                    </input>
                    <h3> Number of lives: </h3>
                    <select
                        value={formData.lives || ''}
                        onChange={(event) => { submitStep(event.target.value) }}>
                        <option key="quantity 1" value="1">1</option>
                        <option key="quantity 2" value="2">2</option>
                        <option key="quantity 3" value="3">3</option>
                    </select>
                    <h3>Entry fee:</h3>
                    <select
                        value={formData.ticketPrice || ''}
                        onChange={(event) => { submitStep(event.target.value) }}>
                        {[...Array(20).keys()].map(i => (
                            <option key={`fee${(i + 1) * 5}`} value={`${(i + 1) * 5000}`}>£{(i + 1) * 5}.00</option>
                        ))}
                    </select>
                    <h3>Start week:</h3>
                    <select
                        className="startdate_dropdown"
                        value={formData.startWeek || ''}
                        onChange={(event) => { submitStep(event.target.value) }}>
                        {[...Array(25).keys()].map(i => (
                            <option key={`startWeek${i + 1}`} value={`gameweek${i + 1}`}>Gameweek {i + 1}</option>
                        ))}
                    </select>
                    <h3>Expected competition entrants:</h3>
                    <Slider
                        orientation='horizontal'
                        onChange={(event) => { submitStep(event.target.value) }}
                        min={0}
                        max={1000}
                        step={25}
                        value={formData.expectedEntrants || ''}
                    />
                    <h4>{formData.expectedEntrants} entrants</h4>
                    <h2>Competition revenue: £{(formData.ticketPrice * formData.expectedEntrants / 1000) || 0}</h2>
                    <h3>Prize payout percentage:</h3>
                    <Slider
                        orientation='horizontal'
                        onChange={(event) => { submitStep(event.target.value) }}
                        min={5}
                        max={90}
                        step={1}
                        value={formData.payoutPercentage || ''}
                    />
                    <h4>{formData.payoutPercentage}%</h4>
                    <h2>Winner payout: £{((formData.ticketPrice * formData.expectedEntrants * formData.payoutPercentage) / 100000) || 0}</h2>
                    <div className="createLeagueNav">
                        <button type="button" onClick={clickBack}>Back</button>
                        <button type="submit">Next</button>
                    </div>
                    <p></p>
                </form>
            </div>
        )
    };

    function StepTwo() {

        // Handle form input changes
        function submitStep(event) {
            const {
                businessType,
                companyName,
                companyUrl,
                individualFirstName,
                individualLastName,
                individualEmail,
                organisationName
            } = event.target;
            setFormData((prevData) => ({
                ...prevData,
                businessType,
                companyName,
                companyUrl,
                individualFirstName,
                individualLastName,
                individualEmail,
                organisationName
            }));
            setStepCount(stepCount + 1);
        };

        return (
            <div className="competition-checkout-container">
                <form onSubmit={submitStep}>
                    <h3>Account type:</h3>
                    <select
                        value={formData.businessType || ""}
                        onChange={(event) => { submitStep(event.target.value) }}>
                        <option key="individual" value="individual">Individual</option>
                        <option key="company" value="company">Company</option>
                        <option key="non-profit" value="non-profit">Non-profit</option>
                    </select>

                    {formData.businessType === 'company' &&
                        <>
                            <h3>Company name:</h3>
                            <input
                                value={formData.companyName}
                                onChange={(event) => { submitStep(event.target.value) }}
                            ></input>
                        </>}

                    {formData.businessType === 'non-profit' &&
                        <>
                            <h3>Organisation name:</h3>
                            <input
                                value={formData.organisationName}
                                onChange={(event) => { submitStep(event.target.value) }}
                            ></input>
                        </>}

                    {formData.businessType !== 'individual' &&
                        <>
                            <h3>{formData.businessType === 'company' ? 'Company' : 'Organisation'} web address:</h3>
                            <input
                                value={formData.companyUrl}
                                onChange={(event) => { submitStep(event.target.value) }}
                            ></input>
                            <h3>First name:</h3>
                            <input
                                value={formData.individualFirstName}
                                onChange={(event) => { submitStep(event.target.value) }}
                            ></input>
                            <h3>Last name:</h3>
                            <input
                                value={formData.individualLastName}
                                onChange={(event) => { submitStep(event.target.value) }}
                            ></input>
                            <h3>Email:</h3>
                            <input
                                value={formData.individualEmail}
                                onChange={(event) => { submitStep(event.target.value) }}
                            ></input>
                        </>}
                    <p></p>
                    <div className="createLeagueNav">
                        <button type="button" onClick={clickBack}>Back</button>
                        <button type="submit">Next</button>
                    </div>
                    <p></p>
                </form>
            </div>
        )
    };

    // Submit form data
    if (stepCount === 3) {
        startStripeConnectOnboarding(formData);
    };

    const pageColour = '#FFFFFF';

    return (
        <main style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width: '100%',
            height: '100%',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            backgroundColor: `#${pageColour}`,
        }}>
            <div style={{
                display: 'flex',
                paddingTop: '3%',
                paddingBottom: '3%',
                margin: '0',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: `#${pageColour}`,
                width: '100 %'
            }}>
                <img src={"/logo.png"} alt="Logo" className={"banner-img-LMS"} />
            </div>
            <Intro />
            {stepCount === 1 && <StepOne />}
            {stepCount === 2 && <StepTwo />}
        </ main >
    );
}


/*

<h3>Company registration number:</h3>
<input
    value={company_reg_num}
    onChange={(event) => { set_company_reg_num(event.target.value) }}>
</input>
<h3>Company structure:</h3>
<select value={company_structure}
    onChange={(event) => { set_company_structure(event.target.value) }}>
    <option value="government_instrumentality">Government Instrumentality</option>
    <option value="governmental_unit">Governmental Unit</option>
    <option value="incorporated_non_profit">Incorporated Non-Profit</option>
    <option value="incorporated_partnership">Incorporated Partnership</option>
    <option value="limited_liability_partnership">Limited Liability Partnership</option>
    <option value="multi_member_llc">Multi-Member LLC</option>
    <option value="private_company">Private Company</option>
    <option value="private_corporation">Private Corporation</option>
    <option value="private_partnership">Private Partnership</option>
    <option value="public_company">Public Company</option>
    <option value="public_corporation">Public Corporation</option>
    <option value="public_partnership">Public Partnership</option>
    <option value="single_member_llc">Single Member LLC</option>
    <option value="sole_proprietorship">Sole Proprietorship</option>
    <option value="tax_exempt_government_instrumentality">Tax-Exempt Government Instrumentality</option>
    <option value="unincorporated_association">Unincorporated Association</option>
    <option value="unincorporated_non_profit">Unincorporated Non-Profit</option>
    <option value="unincorporated_partnership">Unincorporated Partnership</option>
    <option value="free_zone_llc">Free Zone LLC</option>
    <option value="sole_establishment">Sole Establishment</option>
    <option value="free_zone_establishment">Free Zone Establishment</option>
    <option value="llc">LLC</option>
</select>*/
